<template>
	<div class="p-grid crud-demo" :style="{'min-width': calcPageMinWidth}">
		<div class="p-col-12">
			<div class="card" id="maincard">
				<Toast/>

				<Toolbar class="p-mb-1">
					<template v-slot:left>
						<Button label="Lekérdez" :icon="(loading) ? 'pi pi-spin pi-spinner' : 'pi pi-refresh'" class="p-button-success p-mr-2" @click="loadLazyData" style="margin-right: 3rem"/>

                        <Calendar id="datefrom" name="x-beteg-order-datum" v-model.lazy="datefrom" dateFormat="yy.mm.dd" style="width: 10rem" :monthNavigator="true" :yearNavigator="true"  yearRange="2000:2030"
                                :showIcon="true" :showButtonBar="true" 
                                autocomplete="off" :class="(notEmpty(datefrom)) ? 'l-filled-filter': ''"
                        ></Calendar>
                        <label> -tól </label>
                        <Calendar id="dateto" name="x-beteg-order-datumto" v-model.lazy="dateto" dateFormat="yy.mm.dd" style="width: 10rem" :monthNavigator="true" :yearNavigator="true"  yearRange="2000:2030"
                                :showIcon="true" :showButtonBar="true" 
                                autocomplete="off" :class="(notEmpty(dateto)) ? 'l-filled-filter': ''"
                        ></Calendar>
                        <label for="dateto"> -ig</label>
                        <div class="p-d-inline" style="margin-left: 1.5rem; margin-right: 1.5rem">
                            <InputSwitch v-model="showOnlyGrouped" id="showonlygroupedswitch"/>
                            <label for="showonlygroupedswitch">{{labelRows}}</label>
                        </div>
					</template>

					<template v-slot:right>
						<Button label="Export" icon="pi pi-upload" class="p-button-help" @click="exportCSV($event)" 
                                style="position: absolute; right: 30px;" />
					</template>
				</Toolbar>

                <div class="p-d-block" style="margin-left: 1.5rem; margin-right: 1.5rem; margin-bottom: 1.5rem">
                    <label for="selectedColumns">Oszlopok:</label>
                    <MultiSelect id="" v-model="selectedColumns" :options="columns" optionValue="field" optionLabel="header" placeholder="Válassz oszlopot"/>
                </div>

                <!-- Táblázat -->
                <!-- rowGroupMode="subheader" groupRowsBy="home_carer" -->

				<DataTable ref="dt" :value="orders" :paginator="true" :rows="rows"
                            :lazy="true" :totalRecords="totalRecords"
                            sortField="home_carer" :sortOrder="-1" :loading="loading"
                            @filter="onFilter($event)" @page="onPage($event)" @sort="onSort($event)"
                            v-model:filters="filters" filterDisplay="row" responsiveLayout="scroll"
                            :globalFilterFields="['countyname','areaname','home_carer','pharmacy','patientname']"
                            csvSeparator=";" exportFilename="Rendeles_riport"
							paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" :rowsPerPageOptions="[10,100,10000]"
							currentPageReportTemplate="{first} - {last} / {totalRecords}" class="p-datatable-customers p-datatable-sm p-datatable-hoverable-rows ">
                    <template #header>
                        <div class="table-header">
                            <div class="p-d-flex p-ai-center">
                                <h5 class="p-m-0 p-d-inline-block">Riport - Rendelések</h5>
                            </div>
                            <div style="position: absolute; right: 6px; margin-bottom: 1.2rem;">
                                <span class="p-input-icon-left">
                                    <i :class="(loadingSearch) ? ['pi', 'pi-spin', 'pi-spinner'] : ['pi', 'pi-search']"/>
                                    <InputText v-model="searchGlobal" placeholder="Keresés..."/>
                                </span>
                            </div>
                        </div>
                    </template>

                    <Column field="countyname" header="Megye" :showFilterMatchModes="false" v-if="!showOnlyGrouped && selectedColumns.includes('countyname')">
                        <template #filter="{filterModel,filterCallback}">
                            <InputText type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" class="p-column-filter" :placeholder="`Szűrés...`" v-tooltip.top.focus="'ENTER -> szűrés'"
                                    :class="(notEmpty(filterModel.value)) ? 'l-filled-filter': ''"/>
                        </template>
                    </Column>
                    <Column field="areaname" header="Kistérség" :showFilterMatchModes="false" v-if="!showOnlyGrouped && selectedColumns.includes('areaname')">
                        <template #filter="{filterModel,filterCallback}">
                            <InputText type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" class="p-column-filter" :placeholder="`Szűrés...`" v-tooltip.top.focus="'ENTER -> szűrés'"
                                    :class="(notEmpty(filterModel.value)) ? 'l-filled-filter': ''"/>
                        </template>
                    </Column>
                    <Column field="home_carer" header="Ápoló" :showFilterMatchModes="false" v-if="selectedColumns.includes('home_carer')">
                        <template #filter="{filterModel,filterCallback}">
                            <InputText type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" class="p-column-filter" :placeholder="`Szűrés...`" v-tooltip.top.focus="'ENTER -> szűrés'"
                                    :class="(notEmpty(filterModel.value)) ? 'l-filled-filter': ''"/>
                        </template>
                    </Column>
                    <Column field="pharmacy" header="Patika" :showFilterMatchModes="false" v-if="selectedColumns.includes('pharmacy')">
                        <template #filter="{filterModel,filterCallback}">
                            <InputText type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" class="p-column-filter" :placeholder="`Szűrés...`" v-tooltip.top.focus="'ENTER -> szűrés'"
                                    :class="(notEmpty(filterModel.value)) ? 'l-filled-filter': ''"/>
                        </template>
                    </Column>
                    <Column field="patientname" header="Beteg" :showFilterMatchModes="false" v-if="!showOnlyGrouped && selectedColumns.includes('patientname')">
                        <template #filter="{filterModel,filterCallback}">
                            <InputText type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" class="p-column-filter" :placeholder="`Szűrés...`" v-tooltip.top.focus="'ENTER -> szűrés'"
                                    :class="(notEmpty(filterModel.value)) ? 'l-filled-filter': ''"/>
                        </template>
						<template #body="slotProps">
                            <a :href="'/beteg/' + slotProps.data.patientid" style="white-space: pre-wrap" target="_blank">
                                {{ slotProps.data.patientname }}
                            </a>
                        </template>
                    </Column>
                    <Column field="patientsexName" header="Nem" v-if="!showOnlyGrouped && selectedColumns.includes('patientsex')">
                    </Column>
                    <Column field="patientage" header="Kor" v-if="!showOnlyGrouped && selectedColumns.includes('patientage')">
                    </Column>
                    <Column field="firstvisitdate" header="Első látogatás" v-if="!showOnlyGrouped && selectedColumns.includes('firstvisitdate')">
                    </Column>
                    <Column field="sondatype" header="Szonda" v-if="!showOnlyGrouped && selectedColumns.includes('sondatype')">
                    </Column>
                    <Column field="feedtype" header="Táplálás" v-if="!showOnlyGrouped && selectedColumns.includes('feedtype')">
                    </Column>
                    <Column field="patientbno" header="BNO" v-if="!showOnlyGrouped && selectedColumns.includes('patientbno')">
                    </Column>
                    <Column field="patientillname" header="Betegség" v-if="!showOnlyGrouped && selectedColumns.includes('patientillname')">
                    </Column>
                    <Column field="patientbmi_first" header="BMI 1" v-if="!showOnlyGrouped && selectedColumns.includes('patientbmi_first')">
                    </Column>
                    <Column field="patientbmi_last" header="BMI 2" v-if="!showOnlyGrouped && selectedColumns.includes('patientbmi_last')">
                    </Column>
                    <Column field="typeName" header="Típus" v-if="selectedColumns.includes('typeName')">
                        <template #filter="{filterModel,filterCallback}">
                            <MultiSelect v-model="filterModel.value" @change="filterCallback()" :options="typeFilterOptions" optionLabel="label" optionValue="code" placeholder="Válassz..." 
                                scrollHeight="100px" class="p-column-filter" :class="(notEmpty(filterModel.value)) ? 'l-filled-filter': ''" :style="!showOnlyGrouped ? 'width: 70px;' : ''">
                            </MultiSelect>
                        </template>
                    </Column>
                    <Column field="statusName" header="Rendelés állapota" v-if="selectedColumns.includes('statusName')">
                        <template #filter="{filterModel,filterCallback}">
                            <MultiSelect v-model="filterModel.value" @change="filterCallback()" :options="statusFilterOptions" optionLabel="label" optionValue="code" placeholder="Válassz..." 
                                scrollHeight="360px" class="p-column-filter" :class="(notEmpty(filterModel.value)) ? 'l-filled-filter': ''" :style="!showOnlyGrouped ? 'width: 70px;' : ''">
                            </MultiSelect>
                        </template>
                    </Column>
                    <Column field="item" header="Termék" v-if="!showOnlyGrouped && selectedColumns.includes('item')">
                    </Column>
                    <Column field="count" header="Db" v-if="showOnlyGrouped && selectedColumns.includes('count')">
                    </Column>
                    <Column field="amount" header="Mennyiség" v-if="selectedColumns.includes('amount')">
                    </Column>
                    <Column field="doctor_acc" header="Eszközbehelyező" :showFilterMatchModes="false" v-if="!showOnlyGrouped && selectedColumns.includes('doctor_acc')">
                    </Column>
                    <Column field="doctor_treat" header="Kezelőorvos" :showFilterMatchModes="false" v-if="!showOnlyGrouped && selectedColumns.includes('doctor_treat')">
                    </Column>
                    <Column field="evdate1" header="Megrendelve" v-if="!showOnlyGrouped && selectedColumns.includes('evdate1')">
                    </Column>
                    <Column field="evdate2" header="Feldolgozás alatt" v-if="!showOnlyGrouped && selectedColumns.includes('evdate2')">
                    </Column>
                    <Column field="evdate5" header="Teljesítve" v-if="!showOnlyGrouped && selectedColumns.includes('evdate5')">
                    </Column>
                    <Column field="evdate8" header="Elutasítva" v-if="!showOnlyGrouped && selectedColumns.includes('evdate8')">
                    </Column>
                    <Column field="evdate9" header="Visszavonva" v-if="!showOnlyGrouped && selectedColumns.includes('evdate9')">
                    </Column>

                    
                </DataTable>

			</div>
		</div>
	</div>

</template>
<script>

import ReportService from '@/service/backend/report.service'
import {FilterMatchMode} from 'primevue/api';

export default {
	data() {
		return {
			filters: {
                'countyname': {value: null, matchMode: null},
                'areaname': {value: null, matchMode: null},
                'home_carer': {value: null, matchMode: null},
                'pharmacy': {value: null, matchMode: FilterMatchMode.IN},
                'patientname': {value: null, matchMode: FilterMatchMode.IN},
                'typeName': {value: null, matchMode: FilterMatchMode.IN},
                'statusName': {value: null, matchMode: FilterMatchMode.IN},
            },
            rows: 100,
            statusFilter: '',
            searchGlobal: "",
            totalRecords: 0,
            lazyParams: {},
            loading: false,
            loadingSearch: false,
            searchInterval: null,
            showClosed: false,
            datefrom: null,
            dateto: null,
            showOnlyGrouped: true,

            orders: [],

            columns: [
                        { field: 'countyname', header: "Megye" },
                        { field: 'areaname', header: "Kistérség" },
                        { field: 'home_carer', header: "Ápoló" },
                        { field: 'pharmacy', header: "Patika" },
                        { field: 'patientname', header: "Beteg" },
                        { field: 'patientsex', header: "Nem" },
                        { field: 'patientage', header: "Kor" },
                        { field: 'firstvisitdate', header: "Első látogatás" },
                        { field: 'patientbno', header: "BNO" },
                        { field: 'sondatype', header: "Szonda" },
                        { field: 'feedtype', header: "Táplálás" },
                        { field: 'patientbmi_first', header: "BMI 1" },
                        { field: 'patientbmi_last', header: "BMI 2" },
                        { field: 'patientillname', header: "Betegség" },
                        { field: 'typeName', header: "Típus" },
                        { field: 'statusName', header: "Rendelés állapota" },
                        { field: 'item', header: "Termék" },
                        { field: 'count', header: "Db" },
                        { field: 'amount', header: "Mennyiség" },
                        { field: 'doctor_acc', header: "Eszközbehelyező" },
                        { field: 'doctor_treat', header: "Kezelőorvos" },
                        { field: 'evdate1', header: "Megrendelve" },
                        { field: 'evdate2', header: "Feldolgozás alatt" },
                        { field: 'evdate5', header: "Teljesítve" },
                        { field: 'evdate8', header: "Elutasítva" },
                        { field: 'evdate9', header: "Visszavonva" }
            ],
            selectedColumns: [],

            statusNames: {'1':'Megrendelt', '2': 'Feldolgozás alatt', '5':'Teljesítve', '6':'Lezárt', '8':'Elutasítva', '9':'Visszavont'},
            typeNames: {'N':'Tápszer', 'A': 'Eszköz'},
            sexNames: {'F':'Nő', 'M': 'Férfi'},
            typeFilterOptions: [{code:'N', label:'Tápszer'},{code:'A', label: 'Eszköz'}],
            statusFilterOptions: [{code:'',label:'Mind'},{code:'1',label:'Megrendelt'},{code:'2',label:'Feldolgozás alatt'},{code:'5',label:'Teljesítve'},{code:'6',label:'Lezárt'},{code:'8',label:'Elutasítva'},{code:'9',label:'Visszavont'}],

		}
	},
    reportService: null,
    created() {
		this.reportService = new ReportService()
        this.datefrom = this.$dayjs(this.$dayjs().subtract(1, 'month').format('YYYY-MM-') + '01').toDate()
        this.selectedColumns = this.columns.map(e=>{return e.field})
    },
	methods: {
        async loadLazyData(event) {

            this.loading = true

            let lazyParams = { first: 0, sortField: 'home_carer', sortOrder: '-1', page: 0, filters: {}}
            //console.log(lazyParams)
            //console.log(event)

            if(event.page !== undefined){
                lazyParams.first = event.first
                lazyParams.rows = event.rows
                lazyParams.sortField = event.sortField
                lazyParams.sortOrder = event.sortOrder
                lazyParams.page = event.page
                lazyParams.filters = {}
            }

            if(this.filters.countyname.value) lazyParams.filters.countyname = this.filters.countyname.value
            if(this.filters.areaname.value) lazyParams.filters.areaname = this.filters.areaname.value
            if(this.filters.home_carer.value) lazyParams.filters.home_carer = this.filters.home_carer.value
            if(this.filters.patientname.value) lazyParams.filters.patientname = this.filters.patientname.value
            if(this.filters.pharmacy.value) lazyParams.filters.pharmacy = this.filters.pharmacy.value
            if(this.filters.typeName.value) lazyParams.filters.type = this.filters.typeName.value
            if(this.filters.statusName.value) lazyParams.filters.status = this.filters.statusName.value
            //delete lazyParams.filters

            if(this.datefrom != null){
                lazyParams.datefrom = this.$dayjs(this.datefrom).format('YYYY.MM.DD')
            }
            if(this.dateto != null){
                lazyParams.dateto = this.$dayjs(this.dateto).format('YYYY.MM.DD')
            }

            lazyParams.grouped = this.showOnlyGrouped ? 1 : 0

            const result = await this.reportService.getOrders(lazyParams)

            result.data.forEach( e => { 
                    e.typeName = this.typeNames[e.type];
                    e.statusName = this.statusNames[e.status];
                    e.patientsexName = this.sexNames[e.patientsex];
            })

            this.orders = result.data

            this.totalRecords = result.totalRecords

            this.loading = false
        },
        onFilter(event) {
            event.page = 1
            this.loadLazyData(event)
            this.loadingSearch = false
        },
        onPage(event) {
            event.page++
            this.loadLazyData(event)
        },
        onSort(event) {
            this.loadLazyData(event)
        },
        exportCSV() {
            this.$refs.dt.exportCSV()
        },
        notEmpty(val) {
            // TODO ez is lehetne valami mixin, sok helyen kéne használni
            return val!==null && val!=='' && val!==undefined && (val.length==undefined || val.length>0)
        }
    },
    computed: {
        labelRows: function(){
            return this.showOnlyGrouped ? "Sorok nélkül" : "Sorokkal"
        },
        calcPageMinWidth: function() {
            return this.showOnlyGrouped ? '1080px' : '1780px'
        }
    },
    watch: {
        searchGlobal(val) {
            if(val == '') delete this.filters['global']
            clearTimeout(this.searchInterval)
            this.searchInterval = setTimeout(() => {
                this.loadingSearch = true
                setTimeout(() => { this.filters['global'] = val; this.loadingSearch = false }, 20)
            }, 600)
        },
    }
}
</script>
<style>
.p-tag:not(:last-of-type) {
    margin-right: 5px;
}

.p-datatable-thead tr:first-of-type {
    position: relative !important; 
    top: 0 !important;
}
/* Az App.scss-ben megadott sticky valamiért itt nem működik, gyanús, hogy a filter miatt, TODO hogy itt is működjön */

.p-multiselect-panel .p-multiselect-header, 
.p-multiselect-panel .p-multiselect-items .p-multiselect-item {
    padding: 0.2rem 1rem;
}
.p-multiselect-items-wrapper {
    max-height: 450px!important;
}
</style>

<style scoped lang="postcss">

.table-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.order-image {
	width: 100px;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.p-dialog .order-image {
	width: 150px;
	margin: 0 auto 2rem auto;
	display: block;
}

.confirmation-content {
	display: flex;
	align-items: center;
	justify-content: center;
}

.order-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;

	&.status-instock {
		background: #C8E6C9;
		color: #256029;
	}

	&.status-outofstock {
		background: #FFCDD2;
		color: #C63737;
	}

	&.status-lowstock {
		background: #FEEDAF;
		color: #8A5340;
	}
}

.l-row-button {
    visibility: hidden;
}
tr:hover .l-row-button {
    visibility: visible;
}

::v-deep() .p-rowgroup-header {
    background-color: #E0E0E0 !important;
}

::v-deep() .p-rowgroup-footer {
    background-color: #F5F5F5 !important;
}

#maincard {
    padding-top: 0px;
}

@media screen and (max-width: 960px) {
	::deep() .p-datatable {
		&.p-datatable-customers {
			.p-datatable-thead > tr > th,
			.p-datatable-tfoot > tr > td {
				display: none !important;
			}
			.p-datatable-tbody > tr {
				border-bottom: 1px solid var(--surface-d);

				> td {
					text-align: left;
					display: block;
					border: 0 none !important;
					width: 100% !important;
					float: left;
					clear: left;
					border: 0 none;

					.p-column-title {
						padding: .4rem;
						min-width: 30%;
						display: inline-block;
						margin: -.4rem 1rem -.4rem -.4rem;
						font-weight: bold;
					}

					.p-progressbar {
						margin-top: .5rem;
					}
				}
			}
		}
	}

	::deep() .p-toolbar {
		flex-wrap: wrap;

		.p-button {
			margin-bottom: .25rem;
		}
    }

}
</style>
