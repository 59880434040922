import ApiService from './api.service'

export default class ReportService {

	async getCompliances(params) {
        const response = await ApiService.get('api/report/compliance', params)
        return response.data
    }

	async getOrders(params) {
        const response = await ApiService.get('api/report/orders', params)
        return response.data
    }

	async getNewpatients(params) {
        const response = await ApiService.get('api/report/newpatients', params)
        return response.data
    }

}
